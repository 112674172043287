import React from 'react';
import PageLayout from '../components/PageLayout';
import ListMaterialsSuppliers from '../features/materialsSuppliersList/ListMaterialsSuppliers';

const ListMaterialsSupplierPage: React.FC = () => {
  return (
    <PageLayout
      title="List Materials & Suppliers"
      description="View and manage suppliers for materials."
    >
      <ListMaterialsSuppliers />
    </PageLayout>
  );
};

export default ListMaterialsSupplierPage;
