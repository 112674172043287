import React, { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  TextField,
  Button,
  Box,
  Typography,
  Autocomplete,
  CircularProgress,
  Alert,
} from '@mui/material';
import { addSupplier } from '../../api/supplierApi';
import { fetchMaterials } from '../../api/materialApi';
import { QueryKeys } from '../../store/queryKeys';
import PrimaryButton from '../../common/table/PrimaryButton';
import { useAppRoutes } from '../../routes/useAppRoutes';

const SupplierForm: React.FC = () => {
  const [name, setName] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [materialsSupplied, setMaterialsSupplied] = useState<string[]>([]);
  const [materials, setMaterials] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const queryClient = useQueryClient();
  const { goTo } = useAppRoutes();

  useEffect(() => {
    const getMaterials = async () => {
      try {
        setLoading(true);
        const fetchedMaterials = await fetchMaterials();
        setMaterials(fetchedMaterials.map((material) => material.name));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError('Failed to fetch materials');
      }
    };

    getMaterials();
  }, []);

  const addSupplierMutation = useMutation({
    mutationFn: addSupplier,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.SUPPLIERS] });
      setSuccess('Supplier added successfully');
      setName('');
      setContactInfo('');
      setMaterialsSupplied([]);
      setLoading(false);
    },
    onError: (error: any) => {
      setError(error.message);
      setLoading(false);
    },
  });

  const handleAddSupplier = () => {
    setError(null);
    setSuccess(null);
    setLoading(true);
    addSupplierMutation.mutate({ name, contactInfo, materialsSupplied });
  };

  return (
    <Box mb={3} mx="auto" mt={4} maxWidth={600}>
      <Typography variant="h5" gutterBottom>
        Add Supplier
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      <TextField
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        margin="normal"
        size="small"
        disabled={loading}
      />
      <TextField
        label="Contact Information"
        value={contactInfo}
        onChange={(e) => setContactInfo(e.target.value)}
        fullWidth
        margin="normal"
        size="small"
        disabled={loading}
      />
      <Autocomplete
        multiple
        options={materials}
        value={materialsSupplied}
        onChange={(event, newValue) => setMaterialsSupplied(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Materials Supplied"
            margin="normal"
            fullWidth
            size="small"
            disabled={loading}
          />
        )}
      />
      <Box display="flex" justifyContent="flex-end" mt={2} gap={2}>
        <PrimaryButton
          buttonText="Cancel"
          variant="outlined"
          onClick={() => goTo('/list-suppliers')}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddSupplier}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Add Supplier'}
        </Button>
      </Box>
    </Box>
  );
};

export default SupplierForm;
