import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import Login from '../features/auth/Login';
import PrivateRoute from '../components/PrivateRoute';
import RecoverPassword from '../features/auth/RecoverPassword';
import SignUp from '../features/auth/SignUp';
import ConfirmPassword from '../features/auth/ConfirmPassword';
import AddMaterials from '../pages/MaterialsAddPage';
import ListMaterialsPage from '../pages/MaterialsListPage';
import AddSupplierPage from '../pages/SuppliersAddPage';
import ListSupplierPage from '../pages/SuppliersListPage';
import ListMaterialsSupplierPage from '../pages/MaterialsSuppliersListPage';
import ListOrdersPage from '../pages/OrdersListPage';
import OrderExpandableViewPage from '../pages/OrderExpandableViewPage';
import OrderDetailsPage from '../pages/OrderDetailsPage';
import ListCategoriesPage from '../pages/CategoriesListPage';

const Routes: React.FC = () => {
  return (
    <>
      <RouterRoutes>
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/recover-password" element={<RecoverPassword />} />
        <Route path="/confirm-password" element={<ConfirmPassword />} />
        <Route element={<PrivateRoute />}>
          <Route path="/add-supplier" element={<AddSupplierPage />} />
          <Route path="/list-suppliers" element={<ListSupplierPage />} />
          <Route path="/list-categories" element={<ListCategoriesPage />} />
          <Route path="/add-materials" element={<AddMaterials />} />
          <Route path="/list-materials" element={<ListMaterialsPage />} />
          <Route
            path="/list-materials-suppliers"
            element={<ListMaterialsSupplierPage />}
          />
          <Route path="/list-orders" element={<ListOrdersPage />} />
          <Route
            path="/list-suppliers-orders"
            element={<OrderExpandableViewPage />}
          />
        </Route>
        <Route path="/order/:orderId" element={<OrderDetailsPage />} />
        <Route path="/" element={<Navigate to="/login" />} />
      </RouterRoutes>
    </>
  );
};

export default Routes;
