import axiosInstance from './axiosConfig';

export interface Supplier {
  _id: string;
  name: string;
  contactInfo: string;
  materialsSupplied: string[];
}

export const addSupplier = async (
  supplier: Omit<Supplier, '_id'>
): Promise<Supplier> => {
  try {
    const response = await axiosInstance.post<Supplier>(
      '/api/suppliers',
      supplier
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 409) {
      throw new Error('Supplier name already exists');
    } else {
      throw new Error('Server error');
    }
  }
};

export const getSuppliers = async (): Promise<Supplier[]> => {
  try {
    const response = await axiosInstance.get<Supplier[]>('/api/suppliers');
    return response.data;
  } catch (error: any) {
    throw new Error('Server error');
  }
};

export const deleteSuppliers = async (supplierIds: string[]): Promise<void> => {
  try {
    await axiosInstance.delete('/api/suppliers', {
      data: { supplierIds },
    });
  } catch (error: any) {
    console.log({ error });
    throw new Error('Server error');
  }
};
