import React from 'react';
import PageLayout from '../components/PageLayout';
import ListSuppliers from '../features/suppliers/ListSuppliers';
import PrimaryButton from '../common/table/PrimaryButton';
import { useAppRoutes } from '../routes/useAppRoutes';

const ListSupplierPage: React.FC = () => {
  const { goTo } = useAppRoutes();

  const actionButtons = (
    <>
      <PrimaryButton
        buttonText="Add suppliers"
        onClick={() => goTo('/add-supplier')}
      />
    </>
  );
  return (
    <PageLayout
      title="List Supplier"
      description="View and manage your suppliers."
      actionButtons={actionButtons}
    >
      <ListSuppliers />
    </PageLayout>
  );
};

export default ListSupplierPage;
