import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CircularProgress } from '@mui/material';
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Alert,
} from '@mui/material';
import { loginUser } from '../../api/auth';
import { useAppRoutes } from '../../routes/useAppRoutes';
import usePasswordVisibility from '../../common/table/usePasswordVisibility';
import { QueryKeys } from '../../store/queryKeys';

const Login: React.FC = () => {
  const queryClient = useQueryClient();
  const { goTo } = useAppRoutes();

  const { showPassword, PasswordVisibilityToggle } = usePasswordVisibility();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const loginMutation = useMutation({
    mutationFn: loginUser,
    onSuccess: (data) => {
      queryClient.setQueryData([QueryKeys.AUTH], data);
      goTo('/add-materials');
    },
    onError: (error: any) => {
      setError(
        error.response?.data?.message || 'An error occurred during login'
      );
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const handleLogin = async () => {
    setLoading(true);
    setError(null);
    loginMutation.mutate({ username, password });
  };

  const handleRecoverPassword = () => {
    goTo('/recover-password');
  };

  return (
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        <TextField
          label="Username"
          variant="outlined"
          margin="normal"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Password"
          variant="outlined"
          margin="normal"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: PasswordVisibilityToggle,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLogin}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Login'}
        </Button>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{ gap: 2 }}
          mt={5}
        >
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            style={{ marginTop: '1em' }}
          >
            Forgot your password?
            <Button
              variant="text"
              color="secondary"
              onClick={handleRecoverPassword}
            >
              Recover Password
            </Button>
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            style={{ marginTop: '1em' }}
          >
            Don't have an account?{' '}
            <Button
              variant="text"
              color="primary"
              onClick={() => goTo('/sign-up')}
            >
              Sign Up
            </Button>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
