import axios from 'axios';
import { backendUrl } from '../config';

console.log(backendUrl);

const axiosInstance = axios.create({
  baseURL: backendUrl,
});

export default axiosInstance;
