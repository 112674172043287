import React from 'react';
import { Button, ButtonProps } from '@mui/material';

type PrimaryButtonProps = {
  buttonText: string;
} & ButtonProps;

const PrimaryButton: React.FC<PrimaryButtonProps> = (props) => {
  const { buttonText } = props;
  return (
    <Button variant="contained" color="primary" {...props}>
      {buttonText}
    </Button>
  );
};

export default PrimaryButton;
