import React, { useMemo, useState } from 'react';
import { Table, TableContainer, Paper, Box, Typography } from '@mui/material';
import { Material, Order } from '../../api/types';
import OrderTableHead from './OrderTableHead';
import OrderTableBody from './OrderTableBody';

type SortOrder = 'asc' | 'desc';

interface OrderTableProps {
  orders: Order[];
}

const OrderTable: React.FC<OrderTableProps> = ({ orders }) => {
  const [order, setOrder] = useState<SortOrder>('asc');
  const [orderBy, setOrderBy] = useState<keyof Material>('name');
  const [selected, setSelected] = useState<string[]>([]);

  const handleSortRequest = (property: keyof Material) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && orders) {
      const newSelected = orders.map((order) => order._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const filteredMaterials = useMemo(() => {
    if (!orders) return [];
    return orders;
    // TODO: fix filtering
    // return orders.filter(
    //   (order) =>
    //     order.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
    //     (selectedCategory ? order.category === selectedCategory : true) &&
    //     (selectedSubCategory ? order.subCategory === selectedSubCategory : true)
    // );
  }, [orders]);

  const sortedOrders = useMemo(() => {
    return filteredMaterials;
    // TODO: fix sorting
    // return filteredMaterials.sort((a, b) => {
    //   if (orderBy === 'name') {
    //     return order === 'asc'
    //       ? a.name.localeCompare(b.name)
    //       : b.name.localeCompare(a.name);
    //   } else if (orderBy === 'quantity') {
    //     return order === 'asc'
    //       ? a.quantity - b.quantity
    //       : b.quantity - a.quantity;
    //   }
    //   return 0;
    // });
  }, [filteredMaterials, order, orderBy]);

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
        p={2}
      >
        <Box>
          <Typography variant="h5" gutterBottom>
            Materials List
          </Typography>
        </Box>
      </Box>

      {/* TODO: add filters */}
      <Table>
        <OrderTableHead
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleSortRequest}
          numSelected={selected.length}
          rowCount={orders.length}
        />
        <OrderTableBody
          orders={sortedOrders}
          isSelected={isSelected}
          handleClick={handleClick}
        />
      </Table>
    </TableContainer>
  );
};

export default OrderTable;
