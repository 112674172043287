import React, { useMemo, useState } from 'react';
import { Table, TableContainer, Paper, Box, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import MaterialTableHead from './MaterialTableHead';
import MaterialTableBody from './MaterialTableBody';
import DeleteIcon from '@mui/icons-material/Delete';
import { Material } from '../../api/types';
import { deleteMaterials } from '../../api/materialApi';
import MaterialFilters from '../../common/table/MaterialFilters';
import { useAddOrderMutation } from '../OrderAdd/mutations';
import { useAppRoutes } from '../../routes/useAppRoutes';
import DeleteButton from '../../common/table/DeleteButton';
import PrimaryButton from '../../common/table/PrimaryButton';
import SecondaryButton from '../../common/table/SecondaryButton';
import { QueryKeys } from '../../store/queryKeys';

type Order = 'asc' | 'desc';

interface MaterialTableProps {
  materials: Material[];
}

const MaterialTable: React.FC<MaterialTableProps> = ({ materials }) => {
  const queryClient = useQueryClient();
  const { goTo } = useAppRoutes();
  const deleteMaterialsMutation = useMutation({
    mutationFn: deleteMaterials,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.MATERIALS] });
    },
  });

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Material>('name');
  const [selected, setSelected] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [ordering, setOrdering] = useState(false);
  const [orderQuantities, setOrderQuantities] = useState<{
    [key: string]: number;
  }>({});

  const handleSortRequest = (property: keyof Material) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && materials) {
      const newSelected = materials.map((material) => material._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleDelete = () => {
    deleteMaterialsMutation.mutate(selected);
    setSelected([]);
  };

  const handleOrder = () => {
    setOrdering(true);
    const initialOrderQuantities: { [key: string]: number } = {};
    materials.forEach((material) => {
      initialOrderQuantities[material._id] = material.quantity;
    });
    setOrderQuantities(initialOrderQuantities);
  };

  const handleCancelOrder = () => {
    setOrdering(false);
    setOrderQuantities({});
  };

  const {
    addOrderMutation: addOrder,
    // TODO: implement async states
    loading: isAddOrderLoading,
    error: addOrderError,
    success: addOrderSuccess,
  } = useAddOrderMutation();

  const handleGenerateOrder = () => {
    const orderDetails = materials
      .filter((material) => orderQuantities[material._id] !== material.quantity)
      .map((material) => {
        const orderQty = orderQuantities[material._id] - material.quantity;
        const finalQty = material.quantity + (orderQty || 0);
        let status = 'normal';
        if (finalQty < material.minQuantity) {
          status = 'low';
        } else if (finalQty > material.maxQuantity) {
          status = 'extra';
        }

        return {
          material: material,
          orderQuantity: orderQty,
        };
      });
    addOrder.mutate({ materials: orderDetails });
    setOrdering(false);
    setOrderQuantities({});
  };

  const handleOrderQuantityChange = (id: string, quantity: number) => {
    setOrderQuantities((prevOrderQuantities) => ({
      ...prevOrderQuantities,
      [id]: quantity,
    }));
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const filteredMaterials = useMemo(() => {
    if (!materials) return [];
    return materials.filter(
      (material) =>
        material.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (selectedCategory ? material.category === selectedCategory : true) &&
        (selectedSubCategory
          ? material.subCategory === selectedSubCategory
          : true)
    );
  }, [materials, searchQuery, selectedCategory, selectedSubCategory]);

  const sortedMaterials = useMemo(() => {
    return filteredMaterials.sort((a, b) => {
      if (orderBy === 'name') {
        return order === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else if (orderBy === 'quantity') {
        return order === 'asc'
          ? a.quantity - b.quantity
          : b.quantity - a.quantity;
      }
      return 0;
    });
  }, [filteredMaterials, order, orderBy]);

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
        p={2}
      >
        <Box>
          <Typography variant="h5" gutterBottom>
            Materials List
          </Typography>
        </Box>
        <Box display="flex" gap={2} justifyContent="flex-end" flex={1} ml={2}>
          {!ordering ? (
            <>
              <DeleteButton handleDelete={handleDelete} />
              <PrimaryButton
                buttonText="Order"
                onClick={handleOrder}
                variant="outlined"
              />
            </>
          ) : (
            <>
              <PrimaryButton
                buttonText="Generate Order"
                onClick={handleGenerateOrder}
              />
              <SecondaryButton
                buttonText="Cancel"
                onClick={handleCancelOrder}
                startIcon={<DeleteIcon />}
              />
            </>
          )}
        </Box>
      </Box>

      <MaterialFilters
        materials={materials}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        selectedSubCategory={selectedSubCategory}
        setSelectedSubCategory={setSelectedSubCategory}
      />
      <Table>
        <MaterialTableHead
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleSortRequest}
          numSelected={selected.length}
          rowCount={materials.length}
          ordering={ordering}
        />
        <MaterialTableBody
          materials={sortedMaterials}
          isSelected={isSelected}
          handleClick={handleClick}
          ordering={ordering}
          orderQuantities={orderQuantities}
          handleOrderQuantityChange={handleOrderQuantityChange}
        />
      </Table>
    </TableContainer>
  );
};

export default MaterialTable;
