import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Alert,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { confirmPassword } from '../../api/auth';
import { useAppRoutes } from '../../routes/useAppRoutes';

const ConfirmPassword: React.FC = () => {
  const { goTo } = useAppRoutes();

  const [username, setUsername] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const notificationMessage = location.state?.message;

  const confirmPasswordMutation = useMutation({
    mutationFn: confirmPassword,
    onSuccess: (data) => {
      setSuccess(data.message);
    },
    onError: (error: any) => {
      setError(
        error.response?.data?.message || 'Error occurred during password reset'
      );
    },
  });

  const handleConfirmPassword = () => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    confirmPasswordMutation.mutate({ username, verificationCode, newPassword });
    setLoading(false);
  };

  return (
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
        <Typography variant="h4" gutterBottom>
          Confirm Password
        </Typography>
        {notificationMessage && (
          <Alert severity="info">{notificationMessage}</Alert>
        )}
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        <TextField
          label="Username"
          variant="outlined"
          margin="normal"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Verification Code"
          variant="outlined"
          margin="normal"
          fullWidth
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
        />
        <TextField
          label="New Password"
          variant="outlined"
          margin="normal"
          type="password"
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ gap: 2 }}
          mt={5}
        >
          <Button
            variant="contained"
            color="warning"
            onClick={() => goTo('/login')}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleConfirmPassword}
            disabled={loading}
          >
            {loading ? 'Confirming...' : 'Confirm Password'}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ConfirmPassword;
