import React from 'react';
import PageLayout from '../components/PageLayout';
import AddSupplier from '../features/suppliers/AddSupplier';

const AddSupplierPage: React.FC = () => {
  return (
    <PageLayout
      title="Add Supplier"
      description="Fill in the details to add new supplier."
    >
      <AddSupplier />
    </PageLayout>
  );
};

export default AddSupplierPage;
