import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Alert,
} from '@mui/material';
import { signUpUser } from '../../api/auth';
import { useAppRoutes } from '../../routes/useAppRoutes';
import usePasswordVisibility from '../../common/table/usePasswordVisibility';

const SignUp: React.FC = () => {
  const { goTo } = useAppRoutes();
  const { showPassword, PasswordVisibilityToggle } = usePasswordVisibility();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const signUpMutation = useMutation({
    mutationFn: signUpUser,
    onSuccess: (data) => {
      setSuccess(data.message);
    },
    onError: (error: any) => {
      setError(
        error.response?.data?.message || 'An error occurred during sign up'
      );
    },
  });

  const handleSignUp = () => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    signUpMutation.mutate({ username, password, email });
    setLoading(false);
  };

  return (
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
        <Typography variant="h4" gutterBottom>
          Sign Up
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        <TextField
          label="Username"
          variant="outlined"
          margin="normal"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Email"
          variant="outlined"
          margin="normal"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          variant="outlined"
          margin="normal"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: PasswordVisibilityToggle,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSignUp}
          disabled={loading}
        >
          {loading ? 'Signing up...' : 'Sign Up'}
        </Button>
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          style={{ marginTop: '1em' }}
        >
          Already have an account?
          <Button variant="text" color="primary" onClick={() => goTo('/login')}>
            Login
          </Button>
        </Typography>
      </Box>
    </Container>
  );
};

export default SignUp;
