import React, { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import NavBar from './components/NavBar';
import Routes from './routes/Routes';
import { QueryKeys } from './store/queryKeys';

const App: React.FC = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const idToken = localStorage.getItem('idToken');
    const accessToken = localStorage.getItem('accessToken');
    if (idToken && accessToken) {
      queryClient.setQueryData([QueryKeys.AUTH], { idToken, accessToken });
    }
  }, [queryClient]);

  return (
    <>
      <NavBar />
      <Routes />
    </>
  );
};

export default App;
