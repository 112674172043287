import React from 'react';
import {
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Autocomplete,
  TextField,
} from '@mui/material';
import { ISupplier, Material } from '../../api/types';

interface MaterialTableBodyProps {
  materials: Material[];
  suppliers: ISupplier[];
  editMode: boolean;
  isSelected: (id: string) => boolean;
  handleClick: (id: string) => void;
  updateMaterialsState: ({
    materialId,
    supplier,
  }: {
    materialId: string;
    supplier: ISupplier | null;
  }) => void;
}

const MaterialTableBody: React.FC<MaterialTableBodyProps> = ({
  materials,
  suppliers,
  editMode,
  isSelected,
  handleClick,
  updateMaterialsState,
}) => {
  return (
    <>
      <TableBody>
        {materials.map((material) => {
          const isItemSelected = isSelected(material._id);
          return (
            <TableRow
              key={material._id}
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              selected={isItemSelected}
              onClick={() => handleClick(material._id)}
              sx={{ height: '56px' }}
            >
              <TableCell padding="checkbox">
                <Checkbox checked={isItemSelected} />
              </TableCell>
              <TableCell>{material.name}</TableCell>
              <TableCell>{material.category}</TableCell>
              <TableCell>{material.subCategory}</TableCell>
              <TableCell sx={{ width: '33%' }}>
                {!editMode ? (
                  material.preferredSupplier?.name
                ) : (
                  <Autocomplete
                    options={suppliers}
                    getOptionLabel={(option) => option.name}
                    value={material.preferredSupplier}
                    onChange={(event, newValue) =>
                      updateMaterialsState({
                        materialId: material._id,
                        supplier: newValue,
                      })
                    }
                    onClick={(e) => e.stopPropagation()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Preferred Supplier"
                        margin="normal"
                        onClick={(e) => e.stopPropagation()}
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );
};

export default MaterialTableBody;
