import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

type DeleteButtonProps = {
  handleDelete: () => void;
} & ButtonProps;

const DeleteButton: React.FC<DeleteButtonProps> = (props) => {
  const { handleDelete } = props;
  return (
    <Button
      {...props}
      variant="outlined"
      color="secondary"
      onClick={handleDelete}
      startIcon={<DeleteIcon />}
    >
      Delete
    </Button>
  );
};

export default DeleteButton;
