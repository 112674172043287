export interface Material {
  _id: string;
  name: string;
  category: string;
  subCategory: string;
  quantity: number;
  unit: string;
  minQuantity: number;
  maxQuantity: number;
  price: number;
  preferredSupplier: ISupplier | null;
  suppliers: ISupplier[];
  lastUsedSupplier: ISupplier | null;
  orderStatus: OrderStatus;
}

export interface ISupplier {
  _id: string;
  name: string;
  contactInfo: string;
  materialsSupplied: string[];
}

export enum OrderStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  NoOrder = 'NoOrder',
}

export interface IOrderMaterial {
  material: Material;
  orderQuantity: number;
}

export interface Order {
  _id: string;
  orderNumber: string;
  orderDate: Date;
  status: OrderStatus;
  materials: IOrderMaterial[];
  supplier: ISupplier | null;
  isSupplierAssigned: boolean;
  totalAmount: number;
  specialInstructions?: string;
}

export interface OrderInput {
  materials: IOrderMaterial[];
  specialInstructions?: string;
}
