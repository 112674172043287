import React from 'react';
import { Container, Typography, Box } from '@mui/material';

interface PageLayoutProps {
  title: string;
  description: React.ReactNode;
  actionButtons?: React.ReactNode;
  children: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  title,
  description,
  actionButtons,
  children,
}) => {
  return (
    <Container maxWidth="lg" sx={{ mb: '100px' }}>
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          {description}
        </Typography>
        <Box display="flex" flex={1} justifyContent="flex-end" my={3} gap={1}>
          {actionButtons}
        </Box>
        {children}
      </Box>
    </Container>
  );
};

export default PageLayout;
