import React, { useEffect, useMemo, useState } from 'react';
import {
  Table,
  TableContainer,
  Paper,
  Box,
  Typography,
  Alert,
  CircularProgress,
  Button,
} from '@mui/material';
import MaterialTableHead from './MaterialTableHead';
import MaterialTableBody from './MaterialTableBody';
import { ISupplier, Material } from '../../api/types';
import MaterialFilters from '../../common/table/MaterialFilters';
import { batchUpdateMaterials } from '../../api/materialApi';
import { getSuppliers, Supplier } from '../../api/supplierApi';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '../../store/queryKeys';

type Order = 'asc' | 'desc';

interface MaterialTableProps {
  materials: Material[];
}
const MaterialTable: React.FC<MaterialTableProps> = ({ materials }) => {
  const queryClient = useQueryClient();

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Material>('name');
  const [selected, setSelected] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [editMode, setEditMode] = useState(false);

  const [materialsState, setMaterialsState] = useState<Material[]>(materials);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleSortRequest = (property: keyof Material) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && materials) {
      const newSelected = materials.map((material) => material._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const filteredMaterials = useMemo(() => {
    if (materialsState.length === 0) return [];
    return materialsState.filter(
      (material) =>
        material.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (selectedCategory ? material.category === selectedCategory : true) &&
        (selectedSubCategory
          ? material.subCategory === selectedSubCategory
          : true)
    );
  }, [materialsState, searchQuery, selectedCategory, selectedSubCategory]);

  const sortedMaterials = useMemo(() => {
    return filteredMaterials.sort((a, b) => {
      if (orderBy === 'name') {
        return order === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else if (orderBy === 'category') {
        return order === 'asc'
          ? a.category.localeCompare(b.category)
          : b.category.localeCompare(a.category);
      }
      return 0;
    });
  }, [filteredMaterials, order, orderBy]);

  const updateMaterialsMutation = useMutation({
    mutationFn: batchUpdateMaterials,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.MATERIALS] });
      setSuccess('Materials updated successfully');
      setLoading(false);
    },
    onError: (error: any) => {
      setError(error.message);
      setLoading(false);
    },
  });

  const handleUpdateSupplier = ({
    materialId,
    supplier,
  }: {
    materialId: string;
    supplier: ISupplier | null;
  }) => {
    setMaterialsState((prevMaterials) =>
      prevMaterials.map((material) =>
        material._id === materialId
          ? { ...material, preferredSupplier: supplier }
          : material
      )
    );
  };

  const {
    data: suppliers = [],
    error: suppliersError,
    isLoading: suppliersLoading,
  } = useQuery<Supplier[], Error>({
    queryKey: [QueryKeys.SUPPLIERS],
    queryFn: getSuppliers,
  });

  const handleSave = () => {
    setLoading(true);
    updateMaterialsMutation.mutate(materialsState);
  };

  useEffect(() => {
    setMaterialsState(materials);
  }, [materials]);

  return (
    <>
      {success && <Alert severity="success">{success}</Alert>}
      {error ||
        (suppliersError && (
          <Alert severity="error">{suppliersError.message || error}</Alert>
        ))}
      {loading || suppliersLoading ? (
        <CircularProgress />
      ) : (
        <TableContainer
          component={Paper}
          sx={{ boxShadow: 3, borderRadius: 2 }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
            p={2}
          >
            <Box>
              <Typography variant="h5" gutterBottom>
                Materials & Suppliers List
              </Typography>
            </Box>
            <Box
              display="flex"
              gap={2}
              justifyContent="flex-end"
              flex={1}
              ml={2}
            >
              <>
                {editMode ? (
                  <>
                    <Button onClick={handleSave} disabled={loading}>
                      Save
                    </Button>

                    <Button
                      variant="outlined"
                      onClick={() => setEditMode(false)}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Button variant="contained" onClick={() => setEditMode(true)}>
                    Edit
                  </Button>
                )}
              </>
            </Box>
          </Box>

          <MaterialFilters
            materials={materials}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            selectedSubCategory={selectedSubCategory}
            setSelectedSubCategory={setSelectedSubCategory}
          />
          <Table>
            <MaterialTableHead
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleSortRequest}
              numSelected={selected.length}
              rowCount={materials.length}
            />

            <MaterialTableBody
              materials={sortedMaterials}
              updateMaterialsState={handleUpdateSupplier}
              editMode={editMode}
              isSelected={isSelected}
              handleClick={handleClick}
              suppliers={suppliers}
            />
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default MaterialTable;
