import axiosInstance from './axiosConfig';
import { Order, OrderInput } from './types';

export const addOrder = async (newOrder: OrderInput): Promise<Order> => {
  const response = await axiosInstance.post('/api/order', newOrder);
  return response.data;
};

export const getOrders = async (): Promise<Order[]> => {
  const response = await axiosInstance.get('/api/orders');
  return response.data;
};
