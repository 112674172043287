import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MaterialsIcon from '@mui/icons-material/Category';
import SuppliersIcon from '@mui/icons-material/Group';
import GradingIcon from '@mui/icons-material/Grading';
import { useQueryClient } from '@tanstack/react-query';
import NavLink from './NavLink';
import { RouteValues, useAppRoutes } from '../routes/useAppRoutes';
import { QueryKeys } from '../store/queryKeys';

type BottomNavItems = {
  label: string;
  icon: JSX.Element;
  path: RouteValues;
};

const NavBar: React.FC = () => {
  const queryClient = useQueryClient();
  const { goTo } = useAppRoutes();
  const location = useLocation();
  const isAuthenticated = !!queryClient.getQueryData([QueryKeys.AUTH]);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [bottomNavValue, setBottomNavValue] = useState(0);
  const [bottomNavItems, setBottomNavItems] = useState<BottomNavItems[]>([
    { label: 'Materials', icon: <MaterialsIcon />, path: '/list-materials' },
    { label: 'Suppliers', icon: <SuppliersIcon />, path: '/list-suppliers' },
    { label: 'Orders', icon: <GradingIcon />, path: '/list-orders' },
  ]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleLogout = () => {
    queryClient.removeQueries({ queryKey: [QueryKeys.AUTH] });
    goTo('/login');
  };

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const handleBottomNavChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setBottomNavValue(newValue);
    goTo(bottomNavItems[newValue].path);
  };

  const updateBottomNavItems = (newItems: BottomNavItems[]) => {
    setBottomNavItems(newItems);
    setBottomNavValue(0);
    goTo(newItems[0].path);
  };

  const drawerContent = (
    <Box
      sx={{
        width: 250,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                Dental Lab App
              </Typography>
            }
          />
        </ListItem>
        <Divider />
        {!isAuthenticated && (
          <NavLink to="/login" primary="Login in to see your account" />
        )}
        {isAuthenticated && (
          <>
            <ListItem
              button
              onClick={() =>
                updateBottomNavItems([
                  {
                    label: 'Materials',
                    icon: <MaterialsIcon />,
                    path: '/list-materials',
                  },
                  {
                    label: 'Suppliers',
                    icon: <SuppliersIcon />,
                    path: '/list-suppliers',
                  },
                  {
                    label: 'Orders',
                    icon: <GradingIcon />,
                    path: '/list-orders',
                  },
                ])
              }
            >
              <ListItemText primary="Inventory" />
            </ListItem>
          </>
        )}
        {!isAuthenticated &&
          !['/login', '/sign-up'].includes(location.pathname) && (
            <ListItem button onClick={() => goTo('/login')}>
              <ListItemText
                primary={<Typography variant="body1">Login</Typography>}
              />
            </ListItem>
          )}
      </List>
      <Box sx={{ flexGrow: 1 }} />
      {isAuthenticated && (
        <List>
          <Divider />
          <ListItem button onClick={handleLogout}>
            <ListItemText
              primary={<Typography variant="body1">Logout</Typography>}
            />
          </ListItem>
        </List>
      )}
    </Box>
  );

  return (
    <>
      <AppBar position="static">
        <Toolbar variant="dense">
          {isMobile ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Box display="flex" flexGrow={1}>
              {isAuthenticated && (
                <>
                  <NavLink to="/list-materials" primary="Materials" />
                  <NavLink to="/list-suppliers" primary="Suppliers" />
                  <NavLink to="/list-orders" primary="Orders" />
                </>
              )}
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerContent}
      </Drawer>
      {isMobile && isAuthenticated && (
        <BottomNavigation
          value={bottomNavValue}
          onChange={handleBottomNavChange}
          showLabels
          sx={{
            position: 'fixed',
            // height: '70px',
            zIndex: 100,
            borderTop: '1px solid blue',
            bottom: 0,
            width: '100%',
            bgcolor: 'background.paper',
          }}
        >
          {bottomNavItems.map((item, index) => (
            <BottomNavigationAction
              key={index}
              label={item.label}
              icon={item.icon}
            />
          ))}
        </BottomNavigation>
      )}
    </>
  );
};

export default NavBar;
