import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Alert,
} from '@mui/material';
import { recoverPassword } from '../../api/auth';
import { useAppRoutes } from '../../routes/useAppRoutes';

const RecoverPassword: React.FC = () => {
  const { goTo } = useAppRoutes();

  const [username, setUsername] = useState('');
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const recoverPasswordMutation = useMutation({
    mutationFn: recoverPassword,
    onSuccess: () => {
      setSuccess(
        'Password recovery instructions have been sent to your email.'
      );
      setTimeout(() => {
        goTo('/confirm-password', {
          state: {
            message:
              'Password recovery instructions have been sent to your email.',
          },
        });
      }, 2000); // Redirect to confirm-password after 2 seconds
    },
    onError: (error: any) => {
      setError(
        error.response?.data?.message ||
          'An error occurred during password recovery'
      );
    },
  });

  const handleRecoverPassword = () => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    recoverPasswordMutation.mutate({ username });
    setLoading(false);
  };

  return (
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
        <Typography variant="h4" gutterBottom>
          Recover Password
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        <TextField
          label="Email"
          variant="outlined"
          margin="normal"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ gap: 2 }}
          mt={5}
        >
          <Button
            variant="contained"
            color="warning"
            onClick={() => goTo('/login')}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRecoverPassword}
            disabled={loading}
          >
            {loading ? 'Sending instructions...' : 'Send Recovery Instructions'}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default RecoverPassword;
