import React from 'react';
import { TableBody, TableRow, TableCell, Checkbox } from '@mui/material';
import { Order } from '../../api/types';
import PrimaryButton from '../../common/table/PrimaryButton';
import { useAppRoutes } from '../../routes/useAppRoutes';

interface MaterialTableBodyProps {
  orders: Order[];
  isSelected: (id: string) => boolean;
  handleClick: (id: string) => void;
}

const OrderTableBody: React.FC<MaterialTableBodyProps> = ({
  orders,
  isSelected,
  handleClick,
}) => {
  const { goTo } = useAppRoutes();
  return (
    <TableBody>
      {orders.map((order) => {
        const isItemSelected = isSelected(order._id);
        return (
          <TableRow
            key={order._id}
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            selected={isItemSelected}
            onClick={() => handleClick(order._id)}
          >
            <TableCell padding="checkbox">
              <Checkbox checked={isItemSelected} />
            </TableCell>
            <TableCell>
              {new Date(order.orderDate).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              })}
            </TableCell>
            <TableCell>{order.orderNumber}</TableCell>
            <TableCell>{order.supplier?.name}</TableCell>
            <TableCell>{order.status}</TableCell>
            <TableCell>{order.totalAmount}</TableCell>
            <TableCell>{order.specialInstructions}</TableCell>
            <TableCell>
              <PrimaryButton
                onClick={() => goTo(`/order/${order._id}`)}
                buttonText={'Details'}
              />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default OrderTableBody;
