// components/OrderDetailsPage.tsx
import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  Typography,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
} from '@mui/material';
import { getOrders } from '../../api/orderApi';
import { QueryKeys } from '../../store/queryKeys';
import { Order } from '../../api/types';
import PrimaryButton from '../../common/table/PrimaryButton';
import { useAppRoutes } from '../../routes/useAppRoutes';

const OrderDetailsPage: React.FC = () => {
  const { goBack } = useAppRoutes();
  const { orderId } = useParams<{ orderId: string }>();

  const {
    data: orders = [],
    error,
    isLoading,
  } = useQuery<Order[], Error>({
    queryKey: [QueryKeys.ORDERS],
    queryFn: getOrders,
    initialData: [],
  });

  const order = orders.find((order) => order._id === orderId);

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography>Error loading order details</Typography>;
  if (!order) return <Typography>Order not found</Typography>;

  return (
    <Box mb={3} mx="auto" mt={4} maxWidth={800}>
      <PrimaryButton
        buttonText="Back"
        sx={{ my: 2 }}
        onClick={() => goBack()}
      />
      <Paper elevation={3} sx={{ padding: 2 }}>
        <Typography variant="h6">Order Number: {order.orderNumber}</Typography>
        <Typography variant="subtitle1">
          Order Date: {new Date(order.orderDate).toLocaleDateString()}
        </Typography>
        <Typography>Status: {order.status}</Typography>
        <Typography>Total Amount: ${order.totalAmount}</Typography>
        <Typography variant="h6" mt={2}>
          Materials
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Material Name</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Order Quantity</TableCell>
                <TableCell>Total Cost</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.materials.map((material) => {
                const displayPrice = material.material?.price
                  ? `$${material.material?.price}`
                  : '- add price -';
                const totalPrice =
                  material?.orderQuantity * material.material?.price;
                const displayTotalPrice = isNaN(totalPrice)
                  ? '---'
                  : `$${totalPrice}`;

                return (
                  <TableRow key={material.material?._id}>
                    <TableCell>
                      {material.material?.name || `- add material name -`}
                    </TableCell>
                    <TableCell>{displayPrice}</TableCell>
                    <TableCell>{material?.orderQuantity}</TableCell>
                    <TableCell>{displayTotalPrice}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider />
      </Paper>
    </Box>
  );
};

export default OrderDetailsPage;
