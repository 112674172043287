import React from 'react';
import {
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  TextField,
} from '@mui/material';
import { Material } from '../../api/types';
import StatusColumn from './StatusColumn';

interface MaterialTableBodyProps {
  materials: Material[];
  isSelected: (id: string) => boolean;
  handleClick: (id: string) => void;
  ordering: boolean;
  orderQuantities: { [key: string]: number };
  handleOrderQuantityChange: (id: string, quantity: number) => void;
}

const MaterialTableBody: React.FC<MaterialTableBodyProps> = ({
  materials,
  isSelected,
  handleClick,
  ordering,
  orderQuantities,
  handleOrderQuantityChange,
}) => {
  return (
    <TableBody>
      {materials.map((material) => {
        const isItemSelected = isSelected(material._id);
        return (
          <TableRow
            key={material._id}
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            selected={isItemSelected}
            onClick={() => handleClick(material._id)}
          >
            <TableCell padding="checkbox">
              <Checkbox checked={isItemSelected} />
            </TableCell>
            <TableCell>{material.name}</TableCell>
            {ordering ? (
              <TableCell>
                <TextField
                  type="number"
                  value={orderQuantities[material._id] || material.quantity}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    handleOrderQuantityChange(
                      material._id,
                      parseInt(e.target.value, 10)
                    )
                  }
                  inputProps={{ min: 0 }}
                  size="small"
                  sx={{ width: '80px', height: '30px' }}
                />
              </TableCell>
            ) : (
              <TableCell>{material.quantity}</TableCell>
            )}

            <TableCell>{material.unit || 'N/A'}</TableCell>
            <TableCell>{material.minQuantity}</TableCell>
            <TableCell>{material.maxQuantity}</TableCell>
            <TableCell>{material.category}</TableCell>
            <TableCell>{material.subCategory}</TableCell>
            <TableCell>
              <StatusColumn
                quantity={material.quantity}
                minQuantity={material.minQuantity}
                maxQuantity={material.maxQuantity}
              />
            </TableCell>
            <TableCell>{material.orderStatus}</TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default MaterialTableBody;
