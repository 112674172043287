import React from 'react';
import PageLayout from '../components/PageLayout';
import CategoryTable from '../features/Categories/CategoriesTable';

const ListCategoriesPage: React.FC = () => {
  return (
    <PageLayout
      title="List Material Categories"
      description="View and manage your material Categories."
    >
      <CategoryTable />
    </PageLayout>
  );
};

export default ListCategoriesPage;
