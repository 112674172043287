import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addOrder } from '../../api/orderApi';
import { useState } from 'react';
import { QueryKeys } from '../../store/queryKeys';

export const useAddOrderMutation = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const queryClient = useQueryClient();

  const addOrderMutation = useMutation({
    mutationFn: addOrder,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.ORDERS] });
      setSuccess('Order added successfully');
      setLoading(false);
    },
    onError: (error: any) => {
      setError(error.message);
      setLoading(false);
    },
  });

  return { addOrderMutation, loading, error, success };
};
