import React from 'react';
import PageLayout from '../components/PageLayout';
import OrderDetails from '../features/OrderDetails/OrderDetails';

const OrderDetailsPage: React.FC = () => {
  return (
    <PageLayout title="Order Details" description="View order details.">
      <OrderDetails />
    </PageLayout>
  );
};

export default OrderDetailsPage;
