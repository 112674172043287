import React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Checkbox,
} from '@mui/material';
import { Material } from '../../api/types';

type Order = 'asc' | 'desc';

interface MaterialTableHeadProps {
  order: Order;
  orderBy: string;
  numSelected: number;
  rowCount: number;
  onRequestSort: (property: keyof Material) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const OrderTableHead: React.FC<MaterialTableHeadProps> = (props) => {
  const {
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    onSelectAllClick,
  } = props;

  const createSortHandler = (property: keyof Material) => () => {
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        <TableCell sortDirection={orderBy === 'name' ? order : false}>
          <TableSortLabel
            active={orderBy === 'name'}
            direction={orderBy === 'name' ? order : 'asc'}
            onClick={createSortHandler('name')}
          >
            Date
          </TableSortLabel>
        </TableCell>
        <TableCell sortDirection={orderBy === 'quantity' ? order : false}>
          <TableSortLabel
            active={orderBy === 'quantity'}
            direction={orderBy === 'quantity' ? order : 'asc'}
            onClick={createSortHandler('quantity')}
          >
            Order Number
          </TableSortLabel>
        </TableCell>
        <TableCell>Supplier</TableCell>
        <TableCell>Order Status</TableCell>
        <TableCell>Total Amount</TableCell>
        <TableCell>Special Instructions</TableCell>
        <TableCell>View Details</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default OrderTableHead;
