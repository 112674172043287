import React from 'react';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type StatusColumnProps = {
  quantity: number;
  minQuantity: number;
  maxQuantity: number;
};

const StatusColumn = ({
  quantity,
  minQuantity,
  maxQuantity,
}: StatusColumnProps) => {
  const isBelowMinQuantity = quantity < minQuantity;
  const isAboveMaxQuantity = quantity > maxQuantity;

  let percentageDifference = 0;
  let statusMessage = '';
  let color = '';
  let IconComponent = null;

  if (isBelowMinQuantity) {
    percentageDifference = (1 - (minQuantity - quantity) / minQuantity) * 100;
    statusMessage = `Low - ${percentageDifference.toFixed(2)}%`;
    color = 'orange';
    IconComponent = WarningIcon;
  } else if (isAboveMaxQuantity) {
    percentageDifference = ((quantity - maxQuantity) / maxQuantity) * 100;
    statusMessage = `Extra - ${percentageDifference.toFixed(2)}%`;
    color = 'green';
    IconComponent = WarningIcon;
  } else {
    statusMessage = 'Normal';
    color = 'textSecondary';
    IconComponent = CheckCircleIcon;
  }

  return (
    <Typography
      variant="caption"
      color={color}
      display="flex"
      alignItems="center"
    >
      <IconComponent fontSize="small" sx={{ mr: 1 }} />
      {statusMessage}
    </Typography>
  );
};

export default StatusColumn;
