import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Typography } from '@mui/material';
import { Material } from '../../api/types';
import MaterialTable from './MaterialTable';
import { fetchMaterialsList } from '../../api/materialApi';
import { QueryKeys } from '../../store/queryKeys';

const ListMaterialsSuppliers: React.FC = () => {
  const {
    data: materials,
    error,
    isLoading,
  } = useQuery<Material[], Error>({
    queryKey: [QueryKeys.MATERIALS],
    queryFn: fetchMaterialsList,
    initialData: () => [],
  });

  if (isLoading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error loading materials</Typography>;

  return <MaterialTable materials={materials} />;
};

export default ListMaterialsSuppliers;
