import axiosInstance from './axiosConfig';

interface SignUpRequest {
  username: string;
  password: string;
  email: string;
}

interface SignUpResponse {
  message: string;
  user: any;
}

export const signUpUser = async (
  credentials: SignUpRequest
): Promise<SignUpResponse> => {
  const response = await axiosInstance.post<SignUpResponse>(
    '/api/auth/register',
    credentials
  );
  return response.data;
};

interface LoginRequest {
  username: string;
  password: string;
}

interface LoginResponse {
  token: string;
}

interface LoginResponse {
  result: {
    idToken: {
      jwtToken: string;
      payload: Record<string, any>;
    };
    refreshToken: {
      token: string;
    };
    accessToken: {
      jwtToken: string;
      payload: Record<string, any>;
    };
  };
}

export const loginUser = async (
  credentials: LoginRequest
): Promise<LoginResponse> => {
  try {
    const response = await axiosInstance.post<LoginResponse>(
      '/api/auth/login',
      credentials
    );
    const { idToken, accessToken } = response.data.result;
    localStorage.setItem('idToken', idToken.jwtToken);
    localStorage.setItem('accessToken', accessToken.jwtToken);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const logoutUser = async (): Promise<void> => {
  await axiosInstance.post('/api/auth/logout');
  localStorage.removeItem('idToken');
  localStorage.removeItem('accessToken');
};

interface RecoverPasswordRequest {
  username: string;
}

export const recoverPassword = async (
  credentials: RecoverPasswordRequest
): Promise<void> => {
  await axiosInstance.post('/api/auth/forgot-password', credentials);
};

export const confirmPassword = async ({
  username,
  verificationCode,
  newPassword,
}: {
  username: string;
  verificationCode: string;
  newPassword: string;
}) => {
  const response = await axiosInstance.post('/api/auth/confirm-password', {
    username,
    verificationCode,
    newPassword,
  });
  return response.data;
};
