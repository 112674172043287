import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Supplier, deleteSuppliers, getSuppliers } from '../../api/supplierApi';
import DeleteButton from '../../common/table/DeleteButton';
import { QueryKeys } from '../../store/queryKeys';

const ListSuppliersPage: React.FC = () => {
  const [selected, setSelected] = useState<string[]>([]);

  const queryClient = useQueryClient();

  const {
    data: suppliers = [],
    error,
    isLoading,
  } = useQuery<Supplier[], Error>({
    queryKey: [QueryKeys.SUPPLIERS],
    queryFn: getSuppliers,
    initialData: [],
  });

  const deleteSuppliersMutation = useMutation({
    mutationFn: (ids: string[]) => deleteSuppliers(ids),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.SUPPLIERS] });
    },
    onError: (error: any) => {
      console.error('Failed to delete suppliers', error);
    },
    onSettled: () => {
      setSelected([]);
    },
  });

  const handleSelect = (id: string) => {
    setSelected((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  const handleDelete = () => {
    deleteSuppliersMutation.mutate(selected);
  };

  if (error) return <Typography>Error loading suppliers</Typography>;

  return (
    <Box mb={3} mx="auto" mt={4} maxWidth={800}>
      <Typography variant="h5" gutterBottom>
        List of Suppliers
      </Typography>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Box display="flex" justifyContent="flex-end" p={2}>
            <DeleteButton handleDelete={handleDelete} />
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0 && selected.length < suppliers.length
                    }
                    checked={
                      suppliers.length > 0 &&
                      selected.length === suppliers.length
                    }
                    onChange={(event) =>
                      setSelected(
                        event.target.checked
                          ? suppliers.map((supplier) => supplier._id)
                          : []
                      )
                    }
                  />
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Contact Information</TableCell>
                <TableCell>Materials Supplied</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {suppliers.map((supplier) => (
                <TableRow
                  key={supplier._id}
                  selected={selected.includes(supplier._id)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selected.includes(supplier._id)}
                      onChange={() => handleSelect(supplier._id)}
                    />
                  </TableCell>
                  <TableCell>{supplier.name}</TableCell>
                  <TableCell>{supplier.contactInfo}</TableCell>
                  <TableCell>{supplier.materialsSupplied.join(', ')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default ListSuppliersPage;
