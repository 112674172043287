import React from 'react';
import PageLayout from '../components/PageLayout';
import AddMaterial from '../features/materialsAdd/AddMaterial';

const AddMaterials: React.FC = () => {
  return (
    <PageLayout
      title="Add Materials"
      description="Fill in the details to add new materials"
    >
      <AddMaterial />
    </PageLayout>
  );
};

export default AddMaterials;
