// useRoutes.ts
import { useNavigate, NavigateOptions } from 'react-router-dom';
const routes = {
  login: '/login',
  signUp: '/sign-up',
  recoverPassword: '/recover-password',
  confirmPassword: '/confirm-password',
  addSupplier: '/add-supplier',
  listSuppliers: '/list-suppliers',
  addMaterials: '/add-materials',
  listMaterials: '/list-materials',
  listMaterialsSuppliers: '/list-materials-suppliers',
  listOrders: '/list-orders',
  listSupplierOrders: '/list-suppliers-orders',
  home: '/',
} as const;

type ExpandedRouteValues = {
  orderDetails: `/order/${string}`;
};

export type Routes = typeof routes & ExpandedRouteValues;

export type RouteValues = Routes[keyof Routes];

export const useAppRoutes = () => {
  const navigate = useNavigate();

  const goTo = (route: RouteValues, options: NavigateOptions = {}) => {
    navigate(route, options);
  };
  const goBack = () => {
    navigate(-1);
  };

  return {
    routes,
    goTo,
    goBack,
  };
};
