import React from 'react';
import { Box, TextField, Autocomplete } from '@mui/material';
import { Material } from '../../api/types';

interface MaterialFiltersProps {
  materials: Material[];
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  selectedCategory: string;
  setSelectedCategory: (value: string) => void;
  selectedSubCategory: string;
  setSelectedSubCategory: (value: string) => void;
}

const MaterialFilters: React.FC<MaterialFiltersProps> = ({
  materials,
  searchQuery,
  setSearchQuery,
  selectedCategory,
  setSelectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
}) => {
  const options = materials.map((material) => material.name);
  const categoryOptions = Array.from(
    new Set(materials.map((material) => material.category))
  );
  const subCategoryOptions = selectedCategory
    ? Array.from(
        new Set(
          materials
            .filter((material) => material.category === selectedCategory)
            .map((material) => material.subCategory)
        )
      )
    : [];

  return (
    <Box display="flex" justifyContent="space-between" gap={3} p={2}>
      <Autocomplete
        options={options}
        value={searchQuery}
        onInputChange={(event, newValue) => setSearchQuery(newValue)}
        renderInput={(params) => (
          <TextField {...params} label="Name" variant="outlined" size="small" />
        )}
        fullWidth
      />
      <Autocomplete
        options={categoryOptions}
        value={selectedCategory}
        onInputChange={(event, newValue) => setSelectedCategory(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Category"
            variant="outlined"
            size="small"
          />
        )}
        fullWidth
      />
      <Autocomplete
        options={subCategoryOptions}
        value={selectedSubCategory}
        onInputChange={(event, newValue) => setSelectedSubCategory(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Sub Category"
            variant="outlined"
            size="small"
          />
        )}
        disabled={!selectedCategory}
        fullWidth
      />
    </Box>
  );
};

export default MaterialFilters;
