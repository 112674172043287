import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Typography } from '@mui/material';
import { Order } from '../../api/types';
import { getOrders } from '../../api/orderApi';
import OrderTable from './OrderTable';

const ListOrders: React.FC = () => {
  const {
    data: orders,
    error,
    isLoading,
  } = useQuery<Order[], Error>({
    queryKey: ['orders'],
    queryFn: getOrders,
    initialData: () => [],
  });

  if (isLoading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error loading order list</Typography>;

  return <OrderTable orders={orders} />;
};

export default ListOrders;
