import React from 'react';
import PageLayout from '../components/PageLayout';
import ListMaterials from '../features/materialsList/ListMaterials';
import PrimaryButton from '../common/table/PrimaryButton';
import { useAppRoutes } from '../routes/useAppRoutes';

const ListMaterialsPage: React.FC = () => {
  const { goTo } = useAppRoutes();
  const actionButtons = (
    <>
      <PrimaryButton
        buttonText="Manage suppliers"
        onClick={() => goTo('/list-materials-suppliers')}
      />
      <PrimaryButton
        buttonText="Add materials"
        onClick={() => goTo('/add-materials')}
      />
    </>
  );
  return (
    <PageLayout
      title="List Materials"
      description="View and manage your materials."
      actionButtons={actionButtons}
    >
      <ListMaterials />
    </PageLayout>
  );
};

export default ListMaterialsPage;
