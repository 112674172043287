import React from 'react';
import PageLayout from '../components/PageLayout';
import ExpandableOrdersView from '../features/OrdersExpandableView/OrdersExpandableView';
import PrimaryButton from '../common/table/PrimaryButton';
import { useAppRoutes } from '../routes/useAppRoutes';

const OrderExpandableViewPage: React.FC = () => {
  const { goTo } = useAppRoutes();
  const actionButtons = (
    <PrimaryButton
      buttonText="Orders view"
      onClick={() => goTo('/list-orders')}
    />
  );
  return (
    <PageLayout
      title="List Orders"
      description="View and manage your orders."
      actionButtons={actionButtons}
    >
      <ExpandableOrdersView />
    </PageLayout>
  );
};

export default OrderExpandableViewPage;
