import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Supplier, getSuppliers } from '../../api/supplierApi';
import {
  TextField,
  Button,
  Box,
  Autocomplete,
  Grid,
  Typography,
  Alert,
  CircularProgress,
} from '@mui/material';
import axiosInstance from '../../api/axiosConfig';
import { ISupplier, Material, OrderStatus } from '../../api/types';
import { QueryKeys } from '../../store/queryKeys';
import PrimaryButton from '../../common/table/PrimaryButton';
import { useAppRoutes } from '../../routes/useAppRoutes';

const defaultUnits = ['pkt', 'bag', 'box', 'bottle', 'others'];
const defaultCategories: Record<string, string[]> = {
  'Model Materials': [
    'Dental Stone Cleansing Agents',
    'Surfactant Sprays',
    'Die Lubricant Materials',
    'Die Materials',
    'Lab Gypsum Materials',
    'Lab Plaster',
    'Stone Hardeners and Glazes',
    'Utility Resin Materials',
  ],
  'Crown and Bridge Materials': [
    'Casting Wax',
    'Indirect Resin Restorative Material',
    'Investment Materials',
    'Stain and Glaze Systems',
    'Dental Alloys',
  ],
  'CAD/CAM Materials': [
    'Dental CAD/CAM Materials',
    '3D Printing Materials',
    'CAD/CAM Blocks',
    'CAD/CAM Contrast Powder',
  ],
  'Ceramic Materials': ['Dental Ceramic Systems'],
  'Removable Materials': [
    'Denture Acrylic Primers',
    'Denture Acrylic Resins',
    'Denture Repair Acrylic Resins',
    'Denture Teeth',
    'Liquid Foil Materials',
  ],
  'Orthodontic Materials': [
    'Orthodontic Acrylic Additives',
    'Orthodontic Acrylic Resins',
  ],
  'Other Materials': ['Dental Organic Solvent', 'Dental Precision Attachments'],
};

const AddMaterial: React.FC = () => {
  const queryClient = useQueryClient();
  const [name, setName] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [unit, setUnit] = useState('');
  const [minQuantity, setMinQuantity] = useState(0);
  const [maxQuantity, setMaxQuantity] = useState(0);
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [price, setPrice] = useState(0);
  const [preferredSupplier, setPreferredSupplier] = useState<ISupplier | null>(
    null
  );
  const [addMaterialError, setAddMaterialError] = useState<string | null>(null);
  const [addMaterialLoading, setAddMaterialLoading] = useState<boolean>(false);

  const { goTo } = useAppRoutes();

  const {
    data: suppliers = [],
    error,
    isLoading,
  } = useQuery<Supplier[], Error>({
    queryKey: [QueryKeys.SUPPLIERS],
    queryFn: getSuppliers,
  });

  const addMaterial = async (
    newMaterial: Omit<Material, '_id'>
  ): Promise<Material> => {
    setAddMaterialLoading(true);
    setAddMaterialError(null);
    const response = await axiosInstance.post('/api/materials', newMaterial);
    return response.data;
  };

  const addMaterialMutation = useMutation<
    Material,
    Error,
    Omit<Material, '_id'>
  >({
    mutationFn: addMaterial,
    onSuccess: () => {
      setAddMaterialError(null);
      window.alert('Material added successfully');
      queryClient.invalidateQueries({ queryKey: [QueryKeys.MATERIALS] });
    },
    onError: (error: any) => {
      setAddMaterialError(
        error.response?.data?.message ||
          'An error occurred while adding material'
      );
    },
    onSettled: () => {
      setAddMaterialLoading(false);
    },
  });

  const handleAddMaterial = () => {
    if (!name) {
      setAddMaterialError('Material name is required');
    } else {
      addMaterialMutation.mutate({
        name,
        quantity,
        unit,
        minQuantity,
        maxQuantity,
        category,
        subCategory,
        suppliers: [],
        lastUsedSupplier: null,
        preferredSupplier,
        price,
        orderStatus: OrderStatus.NoOrder,
      });
    }
  };

  return (
    <Box mb={3} mx="auto" mt={4} maxWidth={600}>
      {error && <Alert severity="error">{error.message}</Alert>}
      {addMaterialError && <Alert severity="error">{addMaterialError}</Alert>}
      <Typography>Add Material</Typography>

      {isLoading || addMaterialLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                margin="normal"
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Price"
                value={price}
                onChange={(e) => setPrice(Math.max(0, Number(e.target.value)))}
                fullWidth
                margin="normal"
                type="number"
                size="small"
                helperText="The price of the material."
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Quantity"
                value={quantity}
                onChange={(e) => setQuantity(Number(e.target.value))}
                fullWidth
                helperText="Quantity of material already in stock."
                margin="normal"
                type="number"
                size="small"
              />
            </Grid>
          </Grid>
          <Autocomplete
            options={defaultUnits}
            value={unit}
            onChange={(event, newValue) => setUnit(newValue || '')}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                label="Unit"
                margin="normal"
                fullWidth
                size="small"
              />
            )}
          />
          <Autocomplete
            options={Object.keys(defaultCategories)}
            value={category}
            onChange={(event, newValue) => {
              setCategory(newValue || '');
              setSubCategory(''); // Reset subCategory when category changes
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Category"
                margin="normal"
                fullWidth
                size="small"
              />
            )}
          />
          <Autocomplete
            options={category ? defaultCategories[category] : []}
            value={subCategory}
            onChange={(event, newValue) => setSubCategory(newValue || '')}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Sub Category"
                margin="normal"
                fullWidth
                size="small"
              />
            )}
            disabled={!category}
          />
          <Autocomplete
            options={suppliers}
            getOptionLabel={(option) => option.name}
            value={preferredSupplier}
            onChange={(event, newValue) => setPreferredSupplier(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Preferred Supplier"
                margin="normal"
                fullWidth
                size="small"
                helperText="Select the preferred supplier for this material."
              />
            )}
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Min Quantity"
                value={minQuantity}
                onChange={(e) => setMinQuantity(Number(e.target.value))}
                fullWidth
                margin="normal"
                type="number"
                size="small"
                helperText="The minimum quantity that should be maintained in stock."
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Max Quantity"
                value={maxQuantity}
                onChange={(e) => setMaxQuantity(Number(e.target.value))}
                fullWidth
                margin="normal"
                type="number"
                size="small"
                helperText="The maximum quantity that should be maintained in stock."
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" mt={2} gap={2}>
            <PrimaryButton
              buttonText="Cancel"
              variant="outlined"
              onClick={() => goTo('/list-materials')}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddMaterial}
              // TODO: fix loading state
              // disabled={addMaterialMutation.isLoading}
            >
              Add Material
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default AddMaterial;
