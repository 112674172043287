import axiosInstance from './axiosConfig';

export interface Category {
  _id: string;
  name: string;
  subcategories: { id: string; name: string }[];
}

export const getCategories = async (): Promise<Category[]> => {
  const response = await axiosInstance.get('/api/categories');
  return response.data;
};

export const addCategory = async (newCategory: {
  name: string;
  subcategories: string[];
}): Promise<Category> => {
  const response = await axiosInstance.post('/api/categories', newCategory);
  return response.data;
};

export const updateCategory = async (
  id: string,
  updatedCategory: { name: string; subcategories: string[] }
): Promise<Category> => {
  const response = await axiosInstance.put(
    `/api/categories/${id}`,
    updatedCategory
  );
  return response.data;
};

export const deleteCategory = async (id: string): Promise<void> => {
  await axiosInstance.delete(`/api/categories/${id}`);
};
