import { Material } from './types';
import axiosInstance from './axiosConfig';

export const fetchMaterials = async (): Promise<Material[]> => {
  const response = await axiosInstance.get('/api/materials');
  return response.data;
};

export const fetchMaterialsList = async (): Promise<Material[]> => {
  const response = await axiosInstance.get('/api/materials', {
    params: { populate: 'suppliers lastUsedSupplier' },
  });
  return response.data;
};

export const addMaterial = async (
  newMaterial: Omit<Material, '_id'>
): Promise<Material> => {
  const response = await axiosInstance.post('/api/materials', newMaterial);
  return response.data;
};

export const deleteMaterials = async (materialIds: string[]): Promise<void> => {
  await axiosInstance.delete('/api/materials', { data: { materialIds } });
};

export const updateMaterial = async (material: Material): Promise<void> => {
  await axiosInstance.put(`/api/materials/${material._id}`, material);
};

export const batchUpdateMaterials = async (
  materials: Array<Material>
): Promise<void> => {
  await axiosInstance.put('/api/materials', { materials });
};
