import React from 'react';
import { ListItem, ListItemText, Typography } from '@mui/material';
import { useAppRoutes, RouteValues } from '../routes/useAppRoutes';

interface NavLinkProps {
  to: RouteValues;
  primary: string;
  variant?: 'subtitle1' | 'body2';
  marginLeft?: number;
}

const NavLink: React.FC<NavLinkProps> = ({
  to,
  primary,
  variant = 'body2',
  marginLeft = 8,
}) => {
  const { goTo } = useAppRoutes();

  return (
    <ListItem button onClick={() => goTo(to)}>
      <ListItemText
        primary={
          <Typography variant={variant} style={{ marginLeft }}>
            {primary}
          </Typography>
        }
      />
    </ListItem>
  );
};

export default NavLink;
