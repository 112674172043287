import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { useCategoryManagement } from '../../service/useCategoryManagement';

const CategoryTable: React.FC = () => {
  const {
    categories,
    isFetching,
    fetchError,
    addCategory,
    updateCategory,
    deleteCategory,
    isAdding,
    isUpdating,
    isDeleting,
  } = useCategoryManagement();

  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [categoryName, setCategoryName] = useState('');
  const [subcategories, setSubcategories] = useState<string[]>([]);

  const handleOpen = (category: any = null) => {
    setEditMode(!!category);
    setSelectedCategory(category?._id || null);
    setCategoryName(category?.name || '');
    setSubcategories(category?.subcategories || []);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCategory(null);
    setCategoryName('');
    setSubcategories([]);
  };

  const handleSave = () => {
    if (editMode) {
      updateCategory({
        id: selectedCategory!,
        updatedCategory: { name: categoryName, subcategories },
      });
    } else {
      addCategory({ name: categoryName, subcategories });
    }
    handleClose();
  };

  const handleDelete = (id: string) => {
    deleteCategory(id);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpen()}
        >
          <Add /> Add Category
        </Button>
      </Box>
      {fetchError && (
        <Typography color="error">{fetchError.message}</Typography>
      )}
      {isFetching ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Subcategories</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories.map((category) => (
                <TableRow key={category._id}>
                  <TableCell>{category.name}</TableCell>
                  <TableCell>{category.subcategories.join(',')}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpen(category)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(category._id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editMode ? 'Edit Category' : 'Add Category'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Category Name"
            fullWidth
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Subcategories (comma separated)"
            fullWidth
            value={subcategories.join(', ')}
            onChange={(e) => {
              setSubcategories(
                e.target.value.split(',').map((name) => name.trim())
              );
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            disabled={isAdding || isUpdating}
          >
            {isAdding || isUpdating ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CategoryTable;
