import React from 'react';
import { Button, ButtonProps } from '@mui/material';

type SecondaryButtonProps = {
  buttonText: string;
} & ButtonProps;

const SecondaryButton: React.FC<SecondaryButtonProps> = (props) => {
  const { buttonText } = props;
  return (
    <Button variant="outlined" color="secondary" {...props}>
      {buttonText}
    </Button>
  );
};

export default SecondaryButton;
