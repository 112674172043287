import React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Checkbox,
} from '@mui/material';
import { Material } from '../../api/types';

type Order = 'asc' | 'desc';

interface MaterialTableHeadProps {
  order: Order;
  orderBy: keyof Material;
  numSelected: number;
  rowCount: number;
  onRequestSort: (property: keyof Material) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const MaterialTableHead: React.FC<MaterialTableHeadProps> = ({
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  onSelectAllClick,
}) => {
  const createSortHandler = (property: keyof Material) => () => {
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        <TableCell sortDirection={orderBy === 'name' ? order : false}>
          <TableSortLabel
            active={orderBy === 'name'}
            direction={orderBy === 'name' ? order : 'asc'}
            onClick={createSortHandler('name')}
          >
            Name
          </TableSortLabel>
        </TableCell>
        <TableCell sortDirection={orderBy === 'category' ? order : false}>
          <TableSortLabel
            active={orderBy === 'category'}
            direction={orderBy === 'category' ? order : 'asc'}
            onClick={createSortHandler('category')}
          >
            Category
          </TableSortLabel>
        </TableCell>
        <TableCell>Sub Category</TableCell>
        <TableCell>Supplier</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default MaterialTableHead;
