import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Category,
  getCategories,
  addCategory,
  updateCategory,
  deleteCategory,
} from '../api/categoriesApi';
import { QueryKeys } from '../store/queryKeys';

export const useCategoryManagement = () => {
  const queryClient = useQueryClient();

  const {
    data: categories = [],
    error: fetchError,
    isLoading: isFetching,
  } = useQuery<Category[], Error>({
    queryKey: [QueryKeys.CATEGORIES],
    queryFn: getCategories,
  });

  const addCategoryMutation = useMutation({
    mutationFn: (newCategory: { name: string; subcategories: string[] }) =>
      addCategory(newCategory),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.CATEGORIES] });
    },
  });

  const updateCategoryMutation = useMutation({
    mutationFn: ({
      id,
      updatedCategory,
    }: {
      id: string;
      updatedCategory: { name: string; subcategories: string[] };
    }) => updateCategory(id, updatedCategory),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.CATEGORIES] });
    },
  });

  const deleteCategoryMutation = useMutation({
    mutationFn: (id: string) => deleteCategory(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.CATEGORIES] });
    },
  });

  return {
    categories,
    fetchError,
    isFetching,
    addCategory: addCategoryMutation.mutate,
    updateCategory: updateCategoryMutation.mutate,
    deleteCategory: deleteCategoryMutation.mutate,
    isAdding: addCategoryMutation.isPending,
    isUpdating: updateCategoryMutation.isPending,
    isDeleting: deleteCategoryMutation.isPending,
  };
};
