import React from 'react';
import PageLayout from '../components/PageLayout';
import ListOrders from '../features/OrderList/ListOrders';
import PrimaryButton from '../common/table/PrimaryButton';
import { useAppRoutes } from '../routes/useAppRoutes';

const ListOrdersPage: React.FC = () => {
  const { goTo } = useAppRoutes();
  const actionButtons = (
    <PrimaryButton
      buttonText="Supplier view"
      onClick={() => goTo('/list-suppliers-orders')}
    />
  );
  return (
    <PageLayout
      title="List Orders"
      description="View and manage your orders."
      actionButtons={actionButtons}
    >
      <ListOrders />
    </PageLayout>
  );
};

export default ListOrdersPage;
