// components/ExpandableOrdersView.tsx
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  Typography,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getOrders } from '../../api/orderApi';
import { QueryKeys } from '../../store/queryKeys';
import { Order } from '../../api/types';
import PrimaryButton from '../../common/table/PrimaryButton';
import { useAppRoutes } from '../../routes/useAppRoutes';

const ExpandableOrdersView: React.FC = () => {
  const { goTo } = useAppRoutes();
  const {
    data: orders = [],
    error,
    isLoading,
  } = useQuery<Order[], Error>({
    queryKey: [QueryKeys.ORDERS],
    queryFn: getOrders,
    initialData: [],
  });

  if (error) return <Typography>Error loading orders</Typography>;

  // Group orders by supplier
  const ordersBySupplier = orders.reduce(
    (acc: { [key: string]: Order[] }, order) => {
      const supplierName = order.supplier?.name || 'No Supplier';
      if (!acc[supplierName]) {
        acc[supplierName] = [];
      }
      acc[supplierName].push(order);
      return acc;
    },
    {}
  );

  return (
    <Box mb={3} mx="auto" mt={4} maxWidth={800}>
      <Typography variant="h5" gutterBottom>
        Orders by Supplier
      </Typography>
      {isLoading ? (
        <CircularProgress />
      ) : (
        Object.entries(ordersBySupplier).map(
          ([supplierName, supplierOrders]) => (
            <Accordion key={supplierName}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">{supplierName}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {supplierOrders.map((order) => (
                  <Box key={order._id} mb={2}>
                    <Typography variant="subtitle1">
                      Order Number: {order.orderNumber}
                    </Typography>
                    <Typography variant="subtitle2">
                      Order Date:{' '}
                      {new Date(order.orderDate).toLocaleDateString()}
                    </Typography>
                    <Typography>Status: {order.status}</Typography>
                    <Typography>Total Amount: ${order.totalAmount}</Typography>
                    <List>
                      {order.materials.map((material, index) => (
                        <ListItem key={material.material?._id}>
                          <ListItemText
                            primary={material.material?.name}
                            secondary={`Quantity: ${material.orderQuantity}`}
                          />
                        </ListItem>
                      ))}
                    </List>
                    <PrimaryButton
                      onClick={() => goTo(`/order/${order._id}`)}
                      buttonText={'Details'}
                    />

                    <Divider />
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          )
        )
      )}
    </Box>
  );
};

export default ExpandableOrdersView;
